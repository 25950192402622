<template>
	<el-row :gutter="160">
        <el-col :span="24" style="text-align: left;">
            <el-select v-model="form.groupid" placeholder="选择服务器列表" value-key="value" @change="onSelectGroup">
                <el-option v-for="item in selectGroups" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
            </el-select>
            <el-select v-model="form.servername" :multiple="true" placeholder="选择区服" value-key="value" @change="onSelectServer" style="margin-left: 8px;">
                <el-option v-for="item in formServers" :key="item.name" :label="item.name+'-'+item.title" :value="item.name">
                </el-option>
            </el-select>
            <el-select v-model="form.channel" :multiple="true" placeholder="选择频道" value-key="value" @change="onSelectChannel" style="margin-left: 8px;">
                <el-option v-for="item in formChannels" :key="item.name" :label="item.title" :value="item.name">
                </el-option>
            </el-select>
            <el-input v-model="form.cid" placeholder="过滤玩家ID"  @change="onSelectCid" style="margin-left: 8px;width: 120px;"></el-input>
		</el-col>
		<el-col :span="24">
			<el-table size="mini" ref="multipleTable" :data="allchatlog" :height="tableHeight" border style="width: 100%" :row-class-name="logRowClassName"
			:cell-style="{padding:4+'px'}" class="serverlist">
                <el-table-column prop="id" label="id号"> </el-table-column>
                <el-table-column prop="chattime" label="时间" show-overflow-tooltip></el-table-column>
                <el-table-column prop="channel" label="频道" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-if="scope.row.channel==='private'">私聊</span>
                        <span v-if="scope.row.channel==='world'">世界</span>
                    </template>
                </el-table-column>
                <el-table-column prop="fromwho" label="发送者" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{scope.row.cid+'('+scope.row.nickname+')'}}
                    </template>
                </el-table-column>
                <el-table-column prop="towho" label="接收者" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-if="scope.row.channel==='private'">{{scope.row.tocid+'('+scope.row.tonickname+')'}}</span>
                        <span v-if="scope.row.channel==='world'">所有</span>
                    </template>                    
                </el-table-column>  
                <el-table-column prop="word" label="信息内容"  width="512" show-overflow-tooltip></el-table-column>
                <el-table-column prop="flag" label="标志" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-if="scope.row.flag===2">欺诈</span>
                        <span v-if="scope.row.flag===3">敏感</span>
                        <span v-if="scope.row.flag===0">禁言</span>
                        <span v-if="scope.row.flag===1">正常</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button @click.native.prevent="onDetailPlayer(scope.row)" type="success" icon="el-icon-more" size="mini">详情</el-button>
                    </template>
                </el-table-column>
			</el-table>
		</el-col>
        <!-- ========弹框BEGIN====== -->
        <el-dialog title="通过玩家ID查询" :visible.sync="dialogFormCid" width="30%" center class="formparams" :close-on-click-modal="false">
            <el-form :model="form">
                <el-form-item label="玩家ID">
                    <el-input v-model="form.cid" placeholder="请输入玩家ID"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" icon="el-icon-check" @click="onQueryPlayer">确 定</el-button>
            </div>
        </el-dialog>
        <!-- ========弹框END====== -->
        <!-- ========弹框禁言BEGIN====== -->
        <el-dialog :title="notalk.title" :visible.sync="dialogNoTalk" width="30%" center class="formparams" :close-on-click-modal="false">
            <el-form :model="notalk">
                <el-form-item label="玩家ID">
                    <el-input v-model="notalk.cid" placeholder="玩家ID" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="禁言截止时间">
                    <el-date-picker v-model="notalk.time" type="datetime" placeholder="选择截止时间" style="width: 100%;"></el-date-picker>
                </el-form-item>  
                <el-form-item label="">
                    <el-checkbox v-model="notalk.uuid">同时禁言设备ID</el-checkbox>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" icon="el-icon-check" @click="onAddNoTalk">确 定</el-button>
            </div>
        </el-dialog>
        <!-- ========弹框END====== -->
        <!-- ========弹框封号BEGIN====== -->
        <el-dialog title="封号" :visible.sync="dialogFreeze" width="30%" center class="formparams" :close-on-click-modal="false">
            <el-form :model="freeze">
                <el-form-item label="玩家ID">
                    <el-input v-model="freeze.cid" placeholder="玩家ID" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="封号截止时间">
                    <el-date-picker v-model="freeze.time" type="datetime" placeholder="选择截止时间" style="width: 100%;"></el-date-picker>
                </el-form-item>                
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" icon="el-icon-check" @click="onAddFreezeRole">确 定</el-button>
            </div>
        </el-dialog>
        <!-- ========弹框END====== -->   
        <el-dialog title="提示" :visible.sync="dialogLoading" width="24%" :close-on-click-modal="false" :show-close="false">
          <span>正在请求，请稍后...</span>
        </el-dialog>
        <!-- ========弹框改昵称BEGIN====== -->
        <el-dialog title="修改角色昵称" :visible.sync="dialogFormRolename" width="30%" center class="formparams" :close-on-click-modal="false">
            <el-form :model="formRolename">
                <el-form-item label="新昵称">
                    <el-input v-model="formRolename.nickname" placeholder="请输要改成的昵称"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" icon="el-icon-check" @click="onModifyRolename">确 定</el-button>
            </div>
        </el-dialog>
        <!-- ========弹框改昵称END====== -->
        <!-- ========详情BEGIN====== -->
        <el-dialog :visible.sync="dialogFormDetail" width="1000px" height="600px" center class="formdetail" :close-on-click-modal="false" >
          <el-tabs  v-model="activeDetailTab" @tab-click="handleClickDetailTab">
            <el-tab-pane label="账号详情" name="account">
                <el-form :model="curRow" label-width="0px" size="small" :key="updateDetailRow">
                    <el-form-item>
                        <el-row>
                            <el-col :span="12"><el-form-item label="来源渠道:" label-width="120px"><el-input v-model="curRow.channel"></el-input></el-form-item></el-col>
                            <el-col :span="12"><el-form-item label="来源产品:" label-width="120px"><el-input v-model="curRow.product"></el-input></el-form-item></el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12"><el-form-item label="角色id:" label-width="120px"><el-input v-model="curRow.cid"></el-input></el-form-item></el-col>
                            <el-col :span="12"><el-form-item label="账号id:" label-width="120px"><el-input v-model="curRow.username"></el-input></el-form-item></el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12"><el-form-item label="玩家昵称:" label-width="120px"><el-input v-model="curRow.name"></el-input></el-form-item></el-col>
                            <el-col :span="12"><el-form-item label="玩家性别:" label-width="120px"><el-input v-model="curRow.sex"></el-input></el-form-item></el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12"><el-form-item label="注册时间:" label-width="120px"><el-input v-model="curRow.create_time"></el-input></el-form-item></el-col>
                            <el-col :span="12"><el-form-item label="注册IP:" label-width="120px"><el-input v-model="curRow.fromip"></el-input></el-form-item></el-col>
                        </el-row>  
                        <el-row>
                            <el-col :span="12"><el-form-item label="登录次数:" label-width="120px"><el-input v-model="curRow.login_times"></el-input></el-form-item></el-col>
                            <el-col :span="12"><el-form-item label="最后登录时间:" label-width="120px"><el-input v-model="curRow.last_logintime"></el-input></el-form-item></el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12"><el-form-item label="支付总额:" label-width="120px"><el-input v-model="curRow.payfee"></el-input></el-form-item></el-col>
                            <el-col :span="12"><el-form-item label="支付次数:" label-width="120px"><el-input v-model="curRow.paytimes"></el-input></el-form-item></el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12"><el-form-item label="首次付费时间:" label-width="120px"><el-input v-model="curRow.payfirsttime"></el-input></el-form-item></el-col>
                            <el-col :span="12"><el-form-item label="最后付费时间:" label-width="120px"><el-input v-model="curRow.paylasttime"></el-input></el-form-item></el-col>
                        </el-row>                        
                    </el-form-item>
                    <el-form-item label="操作" label-width="120px">
                        <el-button @click.native.prevent="onFreezeRole(curRow)" :type="curRow.frozen?'success':'primary'" icon="el-icon-timer"
                            size="small">{{curRow.frozen?"解封号":"加封号"}}</el-button>                        
                        <el-button @click.native.prevent="onNotalkRole(curRow)" :type="curRow.notalk?'success':'primary'" icon="el-icon-close-notification"
                            size="small">{{curRow.notalk?"解禁言":"加禁言"}}</el-button>
                        <el-button @click.native.prevent="onKickRole(curRow)" type="primary" icon="el-icon-warning-outline"
                            size="small">踢出</el-button>
                        <el-button @click.native.prevent="onWhiteAccount(curRow)" :type="curRow.white?'success':'primary'" icon="el-icon-tickets"
                            size="small">{{curRow.white?"除白名":"加白名"}}</el-button>
                        <el-button @click.native.prevent="onCleanRank(curRow)" type="danger" icon="el-icon-document-delete"
                            size="small">清榜单</el-button>
                        <el-button @click.native.prevent="onClickModifyRolename(curRow)" type="danger" icon="el-icon-edit-outline"
                            size="small">改昵称</el-button>                            
                    </el-form-item>
                </el-form>
            </el-tab-pane>
            <el-tab-pane label="近期登录记录" name="login">
                <el-table :data="tableLoginLog" height="404" style="width: 100%;margin-top: 0px;" :row-class-name="tableRowClassName"
                    :cell-style="{padding:4+'px'}" class="serverlist">
                    <el-table-column prop="create_time" label="登录时间"> </el-table-column>
                    <el-table-column prop="fromip" label="记录IP" show-overflow-tooltip></el-table-column>
                </el-table>
            </el-tab-pane>
            <el-tab-pane label="付费记录" name="pay">
                <el-table ref="multipleTable" :data="tableOrderList" height="404" style="width: 100%;margin-top: 0px;" :row-class-name="tableRowClassName"
                    :cell-style="{padding:4+'px'}" class="serverlist">
                    <el-table-column v-for="item in payLogLabelArr" :key="item.prop" :prop="item.prop" :label="item.label" show-overflow-tooltip></el-table-column>
                    <el-table-column label="操作" width="200">
                        <template slot-scope="scope">
                            <el-button @click.native.prevent="onOrderResend(scope.row)" type="primary" icon="el-icon-thumb"
                                size="small">重发</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
            <el-tab-pane label="礼包码记录" name="giftcode">
                <el-table :data="tableGiftcodeLog" height="404" style="width: 100%;margin-top: 0px;" :row-class-name="tableRowClassName"
                    :cell-style="{padding:4+'px'}" class="serverlist">
                    <el-table-column prop="create_time" label="领取时间" show-overflow-tooltip> </el-table-column>
                    <el-table-column prop="code" label="兑换码" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="state" label="状态" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="fromid" label="兑换码id" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="fromname" label="兑换码名称" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="expire_time" label="过期时间" show-overflow-tooltip></el-table-column>
                </el-table>
            </el-tab-pane>
          </el-tabs>
        </el-dialog>
        <!-- ========详情END====== --> 
	</el-row>
</template>

<script>
	import {
		mapGetters
	} from 'vuex'
    import axios from 'axios'
    import tool from '../../tool.js'

	export default {
		props: ['pkgtype'],
		data() {
			return {
                riskmgtApi: null,
                allchatlog: [],
				serverRows: [],
                formTitle: '',
                form: {
                    cid:'',
                    groupid:'',
                    servername: [],
                    channel:[],
                },
                dialogFormCid:false,
                ///////////////
                formLog: {
                    page: 1,
                    cid: '',
                    queryDate: [],
                },      
                totalChatLog: 0,
				dialogLogForm: false,
                chatLog: [],
                channels: [{id:'world',name:'世界频道'},{id:'game',name:'战斗频道'}],
                choiceDate0: null, // 已选第一个日期
                realtimeChatLog: false, // 标识查看实时聊天
                chatLogTimer: null, // 实时聊天定时器
                pickerOptions: {
                    // 设置不能选择的日期
                    onPick: ({ maxDate, minDate }) => {
                        console.log('onPick', maxDate, minDate);
                        if (maxDate) {
                            this.choiceDate0 = null;
                        } else {
                            this.choiceDate0 = minDate;
                        }
                    },
                    disabledDate: (time) => {
                        if (this.choiceDate0) {
                            return tool.dateMonth(time)!=tool.dateMonth(this.choiceDate0);
                        } else {
                            return false;
                        }
                    }
                },
                //////////////
                formLabelWidth: '124px',
                serverGroups:[],
                formServers:[],
                formChannels:[{name:'world',title:'世界频道'},{name:'private',title:'玩家私聊'}],
                curGroupId: -1, // 当前选择分组(默认全部)
                lastid: 0, // 最新的一条聊天记录id
                // 详情-------
                dialogFormDetail: false,
                activeDetailTab: 'account',
                tableLoginLog:[],
                tableOrderList:[],
                payLogLabelArr:[],
                tableGiftcodeLog:[],
                curRow: {},
                updateDetailRow:0,// 更新表单数据显示，需要更新这个值，不然显示不出来
                // -------------------
                dialogNoTalk: false,
                notalk: {
                    title: '',
                    cid: '',
                    uuid: false,
                },
                // -------------------
                dialogFreeze: false,
                freeze: {
                    cid: '',
                    time: '',
                },    
                // 改昵称-------------
                dialogFormRolename:false,
                formRolename: {
                    cid: '',
                    nickname: '',
                    lastname: '',
                },
                dialogLoading: false,
                tableHeight: document.documentElement.clientHeight-136,
            }
		},
		created: function() {
			this.refreshServerList();
            this.refreshGroupList();
		},
        destroyed: function() {
            if (this.chatLogTimer) {
                clearInterval(this.chatLogTimer);
                this.chatLogTimer = null;
            }
        },
		computed: {
			...mapGetters(
				['gameHttp']
			), 
            selectGroups() {
                return this.serverGroups.slice(1)
            },
		},
		methods: {
            // 发送请求到风控中心
            riskmgtHttp (cmd, data) {
                let rqs = JSON.parse(JSON.stringify(data));
                rqs.cmd = cmd;
                return this.riskmgtApi({
                    method: 'post',
                    url: '/syriskmgt',
                    data: rqs
                });
            },
			// 刷新列表
			refreshServerList() {
				this.gameHttp(3081, {groupid:this.curGroupId}).then((response) => {
					this.serverRows = response.data.list;
                    this.riskmgtApi = axios.create({
                        baseURL: response.data.api,
                        timeout: 5000
                    });
                    // 回来了再请求
                   this.onTopAllChatLog();
                   // 定时刷新
                   this.chatLogTimer = setInterval(() => {
                       this.onTopAllChatLog();
                   }, 2000);
                });
			},
            // 刷新分组列表
            refreshGroupList() {
                this.gameHttp(2006, {plain: 1}).then((response) => {
                    let list = response.data.list
                    this.serverGroups = [{value: -1,label: "全部区服列表"}]
                    for (let i=0; i<list.length; i++) {
                        this.serverGroups.push( {value:list[i].id, label:list[i].name} )
                    }
                })
            },
            // 刷新全部私聊的前n条
            onTopAllChatLog() {
				this.riskmgtHttp(1031,
                    {
                        num: 500, 
                        cid:this.form.cid,
                        servers:this.form.servername,
                        channels:this.form.channel,
                        lastid:this.lastid,
                    },
                ).then((response) => {
                    let len = response.data.list.length;
                    if (len > 0) {
                        this.lastid = response.data.list[0].id;
                        for (let i=len-1; i>=0; i--) {
                            this.allchatlog.splice(0, 0, response.data.list[i]);
                        }                        
                    }
                });
            },
            // 通过玩家cid查询
            onQueryPlayer() {
                this.dialogFormCid = false;
                this.onTopAllChatLog(this.form.cid);
            },
            // 选择服务器分组
            onSelectGroup(itemid) {
                this.gameHttp(2009, { // 拉取服务器列表
                    listid: itemid,
                }).then((response) => {
                    this.form.servername = '';
                    this.formServers = response.data.list;
                })
            },
            // 选择区服
            onSelectServer() {
                console.log("onSelectServer...", this.form.servername);
                this.allchatlog=[];this.lastid = 0;
                this.onTopAllChatLog();
            },  
            // 选择频道
            onSelectChannel() {
                console.log("onSelectChannel...", this.form.channel);
                this.allchatlog=[];this.lastid = 0;
                this.onTopAllChatLog();
            },  
            // 选择cid
            onSelectCid() {
                console.log("onSelectCid...", this.form.cid);
                this.allchatlog=[];this.lastid = 0;
                this.onTopAllChatLog();
            },                        
            // 玩家详情
            onDetailPlayer(row) {
                console.log("onDetailPlayer", row);
                let rqs = {
                    cid: row.cid,
                    player: this.$store.state.game.playerinfo.player,
                    tables: this.$store.state.game.playerinfo.tables,
                };
                console.log(rqs);
                this.gameHttp(3040, rqs).then((response) => {
                    if (0 == response.data.errcode) {
                        this.dialogFormDetail = true;
                        this.activeDetailTab = "account"; 
                        this.curRow = response.data.list[0];
                        this.handleClickDetailTab({name:"account"});
                        this.curRow.product = this.$store.state.game.label;
                        this.curRow.sex = "--";
                        this.updateDetailRow ++;
                    } else {
                        this.$message.warning(response.data.errmsg);
                    }
                });
            },
            // 点击玩家详情tab
            handleClickDetailTab(row) {
                console.log("handleClickDetailTab=>", row.name);
                this.gameHttp(3048, {cid:this.curRow.cid, tabname:row.name}).then((response) => {
                    if (0 == response.data.errcode) {
                        if ("account" == row.name) {
                            this.curRow.payfee = (response.data.payfee/100).toFixed(2);
                            this.curRow.paytimes = response.data.paytimes;
                            this.curRow.payfirsttime = response.data.payfirsttime;
                            this.curRow.paylasttime = response.data.paylasttime;
                            this.updateDetailRow ++;
                        } else if ("login" == row.name) {
                            this.tableLoginLog = response.data.list;
                        } else if ("pay" == row.name) {
                            this.payLogLabelArr = response.data.labelarr;
                            this.tableOrderList = response.data.list;
                        } else if ("giftcode" == row.name) {
                            this.tableGiftcodeLog = response.data.list;
                        }
                    } else {
                        this.$message.warning(response.data.errmsg);
                    }
                });
            },
            // 弹出重发确认框
            onOrderResend(row) {
                this.$confirm('是否重发[ ' + row.orderNo + ' ]订单?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.gameHttp(3032, {
                        id: row.id,
                        channel: this.curRow.channel,
                    }).then((response) => {
                        if (0 == response.data.errcode) {
                            this.$message.success("提交成功")
                            this.onQueryOrder();
                        } else {
                            this.$message.error(response.data.errmsg)
                        }
                    })
                }).catch(() => {})
            },
            // 封号玩家
            onFreezeRole(row) {
                let op = row.frozen ? '解除封号' : '加入封号';
                if (row.frozen) {
                    this.$confirm('是否' + op + '[ '+row.cid+' ]角色?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.gameHttp(3042, {cid:row.cid, add:row.frozen?0:1}).then((response) => {
                            this.dialogForm = false;
                            if (0 == response.data.errcode) {
                                this.onUpdateOnePlayer(row.cid);
                                this.$message.success(op+"成功");
                            } else {
                                this.$message.error(response.data.errmsg);
                            }
                        })
                    }).catch(() => {})                    
                } else {
                    this.dialogFreeze = true;
                    this.freeze.cid = row.cid;
                    this.freeze.time = '';
                }
            },
            // 请求封号
            onAddFreezeRole() {
                if (!this.freeze.time) {
                    this.$message.error('封号截止时间未设置'); return;
                }                
                this.dialogFreeze = false;
                this.dialogLoading = true;
                let endTime = Date.parse(this.freeze.time)/1000;
                this.gameHttp(3042, {cid:this.freeze.cid, add:1,time:endTime}).then((response) => {
                    this.dialogForm = false;
                    this.dialogLoading = false;
                    if (0 == response.data.errcode) {
                        this.onUpdateOnePlayer(this.freeze.cid);
                        this.$message.success("添加成功!");
                    } else {
                        this.$message.error(response.data.errmsg);
                    }
                });
            },
            // 禁言玩家
            onNotalkRole(row) {
                let op = row.notalk ? '解除禁言' : '加入禁言';
                if (row.notalk) { // 解除
                    this.$confirm('是否' + op + '[ '+row.cid+' ]角色?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.dialogLoading = true;
                        this.gameHttp(3043, {cid:row.cid, add:row.notalk?0:1}).then((response) => {
                            this.dialogLoading = false;
                            if (0 == response.data.errcode) {
                                this.onUpdateOnePlayer(row.cid);
                                this.$message.success(op+"成功");
                            } else {
                                this.$message.error(response.data.errmsg);
                            }
                        })
                    }).catch(() => {})
                } else { // 新增
                    this.dialogNoTalk = true;
                    this.notalk.title = op;
                    this.notalk.cid = row.cid;
                    this.notalk.uuid = false;
                }
            },
            // 请求禁言
            onAddNoTalk() {
                if (!this.notalk.time) {
                    this.$message.error('禁言截止时间未设置'); return;
                }                
                this.dialogNoTalk = false;
                this.dialogLoading = true;
                let endTime = Date.parse(this.notalk.time)/1000;
                this.gameHttp(3043, {cid:this.notalk.cid, add:1, uuid:this.notalk.uuid?1:0,time:endTime}).then((response) => {
                    this.dialogLoading = false;
                    if (0 == response.data.errcode) {
                        this.onUpdateOnePlayer(this.notalk.cid);
                        this.$message.success("添加成功");
                    } else {
                        this.$message.error(response.data.errmsg);
                    }
                })
            },
            // 踢出玩家
            onKickRole(row) {
                this.$confirm('是否踢下线[ '+row.cid+' ]角色?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.dialogLoading = true;
                    this.gameHttp(3045, {cid:row.cid}).then((response) => {
                        this.dialogLoading = false;
                        if (0 == response.data.errcode) {
                            this.$message.success("踢出成功");
                        } else {
                            this.$message.error(response.data.errmsg);
                        }
                    })
                }).catch(() => {})
            },            
            // 白名玩家
            onWhiteAccount(row) {
                let op = row.white ? '删除' : '添加';
                this.$confirm('是否' + op + '[ '+row.username+' ]用户白名单?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.gameHttp(3044, {aid:row.aid,cid:row.cid,add:row.white?0:1}).then((response) => {
                        this.dialogForm = false;
                        if (0 == response.data.errcode) {
                            this.onUpdateOnePlayer(row.cid);
                            this.$message.success(op+"白名成功");
                        }
                    })
                }).catch(() => {})
            },
            // 清除排行榜数据
            onCleanRank(row) {
                this.$confirm('是否清除玩家[ '+row.cid+' ]排行榜数据?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.dialogLoading = true;
                    this.gameHttp(3047, {cid:row.cid}).then((response) => {
                        this.dialogLoading = false;
                        if (0 == response.data.errcode) {
                            this.$message.success("清除成功");
                        } else {
                            this.$message.error(response.data.errmsg);
                        }
                    })
                }).catch(() => {})
            },
            // 改昵称
            onClickModifyRolename(row) {
                this.dialogFormRolename = true;
                this.formRolename.cid = row.cid;
                this.formRolename.lastname = row.name;
                this.formRolename.nickname = '';
            },
            onModifyRolename() {
                if (!this.formRolename.nickname.length) {
                    this.$message.warning("请输入玩家昵称"); return;
                }
                this.dialogFormRolename = false;
                this.$confirm('立即提交修改玩家[ '+this.formRolename.cid+' ]的昵称为[' + this.formRolename.nickname + ']?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.dialogLoading = true;
                    this.gameHttp(3049, {cid:this.formRolename.cid,nickname:this.formRolename.nickname,lastname:this.formRolename.lastname}).then((response) => {
                        this.dialogLoading = false;
                        if (0 == response.data.errcode) {
                            this.$message.success("修改成功");
                        } else {
                            this.$message.error(response.data.errmsg);
                        }
                    })
                }).catch(() => {})
            },            
            // 更新一名玩家数据
            onUpdateOnePlayer(cid) {
                let rqs = {
                    cid: cid,
                    player: this.$store.state.game.playerinfo.player,
                    tables: this.$store.state.game.playerinfo.tables,
                };
                this.gameHttp(3041, rqs).then((response) => {
                    if (0 == response.data.errcode) {
                        if (response.data.list.length > 0) {
                            let item = response.data.list[0];
                            for (let k in item) {
                                this.curRow[k] = item[k];
                            }
                        }
                    } else {
                        this.$message.warning(response.data.errmsg);
                    }
                })
            },
            // 日志表标色
            logRowClassName({
                row
            }) {
                if (2 == row.flag) {
                    return 'log-error-row';
                } else if (3 == row.flag) {
                    return 'log-warnlog-row';
                } else if (0 == row.flag) {
                    return 'log-graylog-row';
                }
            },
			// 数据表色彩间隔效果     
			tableRowClassName({
				rowIndex
			}) {
				if (0 == rowIndex % 2) {
					return 'warning-row';
				} else {
					return 'success-row';
				}
			},
		},
	}
</script>

<style>
	.serverlist.el-table .warning-row {
		background: #f5f7fa;
	}

	.serverlist.el-table .success-row {
		background: #ffffff;
	}

	.serverlist.el-table {
		margin-top: 16px;
	}

	.serverlist {
		font-size: 12px;
	} 
    
    .serverlist.el-table .log-error-row {
        color: #aa0000;
    }   
    .serverlist.el-table .log-warnlog-row {
        color: #ff5500;
    } 
    
    .serverlist.el-table .log-graylog-row {
        color: #aa557f;
    }
</style>
